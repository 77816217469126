import { gql } from '@apollo/client';

const GET_PROJECTS = gql`
{
  projects {
    edges {
      node {
        id
        title
        date
        content
        excerpt
        projectTags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
}

`;


const GET_SINGLE_PROJECT_BY_ID = gql`
  query GetSinglePost($id: ID!) {
    project(id: $id) {
      id
      title
      date
      content
      excerpt
      projectTags {
          nodes {
            name
          }
        }
      featuredImage {
        node {
          sourceUrl
        }
      }
      projectCategories {
        nodes {
          name
          slug
        }
      }
    }
  }
`;

const GET_PROJECT_TAGS = gql`
 {
  projectTags {
    nodes {
      id
      name
      slug
      description
    }
  }
}
`;

const GET_PROJECTS_BY_TAG = gql`
  query GET_PROJECTS_BY_TAG_NAME($tagName: String!) {
    projects(where: { tag: $tagName }) {
      nodes {
        id
        title
        content
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        projectTags {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;

const GET_PROJECTS_BY_CATEGORY = gql`
  query GET_PROJECTS_BY_CATEGORY_NAME($categoryName: String!) {
    projects(where: { categoryName: $categoryName }) {
      nodes {
        id
        title
        content
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        projectCategories {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;


const GET_PROJECTS_AND_CATEGORIES = gql`
{
  projects {
    edges {
      node {
        id
        title
        date
        content
        excerpt
        projectTags {
          nodes {
            name
          }
        }
        projectCategories {
          nodes {
            name
            id
            slug
            description
            parentId
          }
        }
        projectTags {
          nodes {
            id
            name
            slug
            description
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
  projectCategories {
    nodes {
      id
      name
      slug
      description
      parentId
    }
  }
}
`;

const GET_SKILLS = gql`
 {
  skills {
    edges {
      node {
        title
        featuredImage{
          node{
            sourceUrl
          }
        }
      }
    }
  }
}

`;

const GET_BLOGPOSTS = gql`
 {
  posts {
    edges {
      node {
        id
        title
        date
        content
        excerpt
        author {
          node {
            name
          }
        }
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
}
`;

const GET_SINGLE_BLOGPOST_BY_ID = gql`
    query GetSinglePost($id: ID!) {
      post(id: $id) {
      id
      title
      date
      content
      excerpt
      tags {
          nodes {
            name
          }
        }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`;

const GET_PAGE_CONTENT = gql`
  query GetPageContent($slug: String!) {
    pageBy(uri: $slug) {
      id
      title
      content
    }
  }
`;




export { 
  GET_PROJECTS,
  GET_SINGLE_PROJECT_BY_ID,
  GET_PROJECT_TAGS,
  GET_PROJECTS_BY_TAG,
  GET_PROJECTS_BY_CATEGORY,
  GET_PROJECTS_AND_CATEGORIES,
  GET_SKILLS,
  GET_BLOGPOSTS,
  GET_SINGLE_BLOGPOST_BY_ID,
  GET_PAGE_CONTENT
}