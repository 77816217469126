import React from "react";
import { NavLink, useLocation } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <footer className="kb_section">
        <div className="kb_footer">
          <div className="container">
            <div className="inner">

            <p class = "footer-contact-link">
              <a href="mailto:hoi@k3vin.ch">hoi@k3vin.ch</a>
            </p>

            <p class = "footer-contact-link">
              <a href="tel:+41 76 844 43 33">+41 76 844 43 33</a>
            </p>

            <p class = "footer-nav-link footer-contact-link">
              <NavLink to="/page/impressum" target="_blank" rel="noopener noreferrer">
                Impressum
              </NavLink>
            </p>

            <p class = "footer-nav-link footer-contact-link">
              <NavLink to="/page/datenschutzerklarung" rel="noopener noreferrer">
                Datenschutz
              </NavLink>
            </p>
            </div>
            {/* End inner */}
          </div>
        </div>
        {/* End kb_footer */}
      </footer>
    </>
  );
};

export default Footer;
