import React, { useEffect, useRef } from "react";

const Map = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    const map = new window.google.maps.Map(mapRef.current, {
      center: { lat: 46.954000296438856, lng: 7.4495233815711375 },
      zoom: 12,
      zoomControl: true, // Aktiviert Zoom-Steuerelemente
      mapTypeControl: false, // Deaktiviert Kartenart-Steuerelemente
      scaleControl: false, // Deaktiviert Maßstabs-Steuerelemente
      streetViewControl: false, // Deaktiviert Street View-Steuerelemente
      rotateControl: false, // Deaktiviert Dreh-Steuerelemente
      fullscreenControl: false // Deaktiviert Vollbild-Steuerelemente
    });

    return () => {
      mapRef.current = null;
    };
  }, []);

  return (
    <div
      ref={mapRef}
      // style={{ width: "380px", height: "360px" }}
    />
  );
};

export default Map;
