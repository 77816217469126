import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Skeleton from '@mui/material/Skeleton';
import { GET_PAGE_CONTENT } from '../backend/api/queries'; 
import { useParams, useLocation } from 'react-router-dom';


export default function SinglePage() {
  const { pageSlug } = useParams(); 
  const location = useLocation();  
  
  const { loading, error, data } = useQuery(GET_PAGE_CONTENT, {
    variables: { slug: pageSlug }
  });
  
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    if (data && !loading && !error) {
      setPageContent(data.pageBy.content);
      console.debug("Location state/////////////777:", location.state);  
    }
  }, [data, loading, error]);

  return (
<>
  <Header stayWhite={true} />

  <section className="kb_section single single-page">
    <div className="container">
      {loading ? (
        <Skeleton variant="rectangular" height={500} classes={{ root: "page-skeleton-box" }} />
      ) : error ? (
        <div>
          <p>Die gewünschte Seite konnte nicht geladen werden.</p>
        </div>
      ) : (
        <div className="page-content">
          <h1>{data.pageBy.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: pageContent }} />
        </div>
      )}
    </div>
  </section>

  <Footer />
</>
  );
}
