import React, { useEffect } from "react";
import AllRouter from "./router/AllRouter";
import ScrollToTopButton from "./components/ScrollToTopButton";
import ScrollToTop from "./router/scrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";

// Graph QL / Database 
import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
const client = new ApolloClient({

  uri: 'https://kevinadrian.ch/wp/graphql',

  cache: new InMemoryCache(),

});


const App = () => {
  useEffect(() => {
    AOS.init();
    document.body.classList.add("loaded");
  }, []);
  return (
    <ApolloProvider client={client}>
      <div className="kb_all_wrap">
        <ScrollToTop />
        <ScrollToTopButton />
        <AllRouter />
      </div>
    </ApolloProvider>
  );
};

export default App;
