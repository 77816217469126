import React from "react";
import { useForm } from "react-hook-form";
import useMailSender from '../../backend/api/useMailSender.jsx';
import { NavLink, useLocation } from 'react-router-dom';

const Contact = () => {
 
  const subject = "k3vin.ch - Anfrage"
 
 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { sendEmail } = useMailSender();

  const onSubmit = (data, e) => {
    e.preventDefault();
    sendEmail(data.name, data.sendermail, data.phone, subject, data.message);
    e.target.reset();
  };

  return (
    <>
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="Name"
              />
              {errors.name && errors.name.type === "required" && (
                <span>Dr Name bruuchts no</span>
              )}
            </li>
            {/* End name field */}

            <li>
              <input
                {...register(
                  "sendermail",
                  {
                    required: "D Email bruuchts oh no",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Das gseht mir nid nachere Email us",
                    },
                  }
                )}
                type="email"
                placeholder="Email"
              />
              {errors.sendermail && <span role="alert">{errors.sendermail.message}</span>}
            </li>
            {/* End email field */}

        
            <li>
              <input
                {...register(
                  "phone",
                  {
                    required: "Darfi no dini Nummere ha?",
                    pattern: {
                      value: /^[+]?[0-9\s\-()]{7,15}$/, 
                      message: "Das gseht mir nicht nachemne Telefon us",
                    },
                  }
                )}
                type="tel"
                placeholder="Telefon"
              />
              {errors.phone && <span role="alert">{errors.phone.message}</span>}
            </li>
            {/* End phone field */}


            <li>
              <textarea
                {...register("message", { required: true })}
                placeholder="Nachricht"
              ></textarea>
              <p class = "datenschutz-link">
                <NavLink to="/page/datenschutzerklarung" target="_blank" rel="noopener noreferrer">
                  Dateschutzerklärig
                </NavLink >
              </p>

              {errors.message && <span>Um was geits übrhaupt?</span>}
            </li>
            {/* End message field */}
          </ul>
        </div>

        <div className="tokyo_button">
          <button type="submit" className="white-fill-bg fill-black">
            Nachricht abschicke
          </button>
        </div>
        {/* End tokyo_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
