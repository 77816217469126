import React, { useState, useEffect } from "react";
import BrandSlider from './BrandSlider';

import { useQuery} from '@apollo/client';
import { GET_SKILLS} from '../../backend/api/queries';

export default function SkillLogoBar() {

    const [skills, setSkills] = useState(null);
    const [skillsReady, setSkillsReady] = useState(null)
    const { loading, error, data } = useQuery(GET_SKILLS);
  
    useEffect(()=>{
  
      if (data && skills === null && !loading && !error){
        var imgLinks = data.skills.edges.map(skill => skill.node.featuredImage.node.sourceUrl);
        setSkills(imgLinks);
        setSkillsReady(true);
      }
  
    }, [data, skills])


  return (
    <div className="kb_section">
    <div className="kb_partners">
      <div className="container">
        <div className="partners_inner">

          <BrandSlider skills={skills} />

        </div>
      </div>
    </div>
  </div>
  )
}
