import React, {useState} from "react";
import ContactPopUp from "../Contact/ContactPopUp";

const About = () => {

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }


  return (
    <>

      <ContactPopUp isOpen = {isOpen} toggleModal={toggleModal} />

      <section className="kb_section" id="about">
        <div className="kb_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div className="image">
                  <img src="/img/placeholders/1-1.jpg" alt="placeholder" />
                  <div
                    className="main"
                    style={{ backgroundImage: "url(img/about/4.png)" }}
                  ></div>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div className="kb_title">
                  <span>About Me</span>
                  <h3>Kreative Multimediaproduzent vo Bärn</h3>
                </div>
                <div className="text">
                  <p>
                  Als junge und kreative Multimediaproduzent ha ig mi uf d Entwicklig vo Web- und Appawändige spezialisiert. Mini Erfahrig und Lydeschaft für kreativi Konzeption und technischi Umsetzig ermöglichts mir, innovativi und lässigi Lösige für di z entwickle. Rede mir doch mau über dini Idee und machä öppis Grossartigs zäme.
                  </p>
                </div>

                <nav className = "about-actions">

                  {/* <div className="kb_button kb_button_v2 btn_left">
                    <a href="downloads/cv-kevinbieri.pdf" download>
                      CV abelade
                    </a>
                  </div> */}

                  
                  <div className="kb_button btn_right">
                    <a href="#contact" onClick={toggleModal}>
                      Kontakt
                    </a>
                  </div>

                </nav>

              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </section>
    </>
  );
};

export default About;
