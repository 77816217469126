import React from "react";
import Header from "../components/header/Header";
import Hero from '../components/hero/Hero';
import About from "../components/about/About";
import SkillLogoBar from "../components/skillLogos/SkillLogoBar";
import Portfolio from "../components/portfolio/Portfolio";
import Video from "../components/video/Video";
import News from "../components/blog/Blog";
import Contact from "../components/Contact/Contact";
import Footer from "../components/footer/Footer";

const Home = () => {
  return (
    <main className="home-view">
      <Header />
      {/* End Header Section */}

      <Hero />
      {/* End Slider Section */}

      <About />
      {/* End About Section */}

      <SkillLogoBar />
      {/* End Skills Section */}

      <Portfolio />
      {/* End Portfolio Section */}

      <Video />
      {/* End Video Section */}

      {/* End  kb_testimonials*/}

      <News />
      {/* End Blog Section */}

      <Contact/>
      {/* End CallToAction */}

      <Footer />
      {/* End Footer Section */}
    </main>
  );
};

export default Home;
