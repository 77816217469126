import DOMPurify from 'dompurify'; // XSS Security Add-on for the HTML Content from Wordpress DB
import { format, parseISO } from 'date-fns';

class Project {
    constructor(project){
        this.id = project.id;
        this.date = this.convertTime(project.date);
        this.title = project.title;
        this.excerpt = project.excerpt;
        this.content = project.content;
        this.tags = project.projectTags.nodes
        this.categories = project.projectCategories.nodes
        this.thumbnail = project.featuredImage.node.sourceUrl;
      }

      convertTime(wpdate){
        let parsedDate = parseISO(wpdate);
        let formattedDate = format(parsedDate, 'dd.MM.yyyy');
        return formattedDate;
      }
}


class BlogPost {
  constructor(blogpost){
      this.id = blogpost.id;
      this.date = this.convertTime(blogpost.date);
      this.title = blogpost.title;
      this.excerpt = blogpost.excerpt;
      this.content = blogpost.content;
      this.author = blogpost.author?.node?.name; 
      this.tags = blogpost.tags.nodes
      this.thumbnail = blogpost.featuredImage.node.sourceUrl;
    }

    convertTime(wpdate){
      let parsedDate = parseISO(wpdate);
      let formattedDate = format(parsedDate, 'dd.MM.yyyy');
      return formattedDate;
    }
}


function cleanPostsData(rawDataObj, postType){
 
    let rawDataArray
    let tempProjects = [];

    switch (postType) {
      case "project":
        rawDataArray = rawDataObj.projects.edges
        break;
       case "blogpost":
        rawDataArray = rawDataObj.posts.edges
        break;
      default:
        rawDataArray  = ["Error: missing postType (sortPost Function: api-functions.js)"]
        break;
    }

    rawDataArray.forEach(rawData => {
        let filteredData = createCleanPostData(rawData, postType, true)
        tempProjects.push(filteredData)
    });

    return tempProjects;

}


function createCleanPostData(rawDataObj, postType, dataHasNodes){
 

    let filteredData


    switch (postType) {
      case "project":
        if (dataHasNodes) {
          filteredData = new Project(rawDataObj.node);
        } else {
          filteredData = new Project(rawDataObj.project);
        }
        break;
    
      case "blogpost":
        if (dataHasNodes) {
          filteredData = new BlogPost(rawDataObj.node);
        } else {
          filteredData = new BlogPost(rawDataObj.post);
        }
        break;

      default:
        filteredData =  ["Error: missing postType (createCleanPostData - Function: api-functions.js)"]
        break;
      }


    return filteredData;

}





const purifyConfig = {
  ADD_TAGS: ['iframe'],
  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
};

function renderWPpreformattedContent(content, className) {

  let sanitizedHtml = DOMPurify.sanitize(content, purifyConfig);

  if (className) {
    return <div className={className} dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  } else {
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  }
}


  function stripHTML(content) {

    // Bereinige den HTML-Inhalt
    const sanitizedHtml = DOMPurify.sanitize(content);
    
    // Erstelle einen temporären DOM-Knoten, um den reinen Text zu extrahieren
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedHtml;
    
    // Extrahiere und returne den reinen Text
    return tempDiv.textContent || tempDiv.innerText || '';
  }

export {cleanPostsData, createCleanPostData, renderWPpreformattedContent, stripHTML}
