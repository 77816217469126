import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function PortfolioSkeleton() {
  return (
    <li>
        <Skeleton variant="rectangular" height={225} classes={{ root: "blog-skeleton-box" }} />
        <Skeleton variant="rect" height={18} classes={{ root: "blog-skeleton-h3-line" }} />
    </li>
  )
}
