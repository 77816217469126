import React from 'react'
import { useNavigate } from "react-router-dom";
import { stripHTML } from '../../backend/api/api-functions';

export default function PortfolioBox({dataIndex, data}) {

    const navigate = useNavigate()

    function linkPortfolioBox() {
      navigate(`/project/${data.title}/${data.id}`, { state: { cachedPost: data } });
    }
    

  return (
    <li className='portfolio-li' key={dataIndex} >
      <div className="entry kb_portfolio_animation_wrap">
      
            <img
              src={data.thumbnail}
              alt="portfolio"
              role="button"
              onClick={linkPortfolioBox}
            />

      </div>
      
      <div className="mobile_title">
        <h3>
          <a
            href={data.portfolioLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {stripHTML(data.title)}
          </a>
        </h3>
        <span>{data.meta}</span>
      </div>
  </li>
  )
}
