import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function BrandSlider({skills}) {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    responsive: [
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      {skills ? (
        <ul>
          <Slider {...settings}>
            {skills.map((skill, i) => (
              <li className="item" key={i}>
                <img src={skill} alt="skill brand" />
              </li>
            ))}
          </Slider>
        </ul>
      ) : (
        <div>lade...</div>
      )}
    </>
  );
}