import React from "react";
import Home from "../views/Home";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import SinglePost from '../views/SinglePost';
import SinglePage from '../views/SinglePage';

const AllRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/page/:pageSlug" element={<SinglePage />} />
        <Route path="/:POST_TYPE/:title/:POST_ID" element={<SinglePost />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AllRouter;
