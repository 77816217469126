import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { useQuery} from '@apollo/client';
import { GET_PROJECTS_AND_CATEGORIES } from '../../backend/api/queries';
import { cleanPostsData } from '../../backend/api/api-functions'

import PortfolioBox from "./PortfolioBox";
import PortfolioSkeleton from "./PortfolioSkeleton";
import Skeleton from '@mui/material/Skeleton';

const Portfolio = () => {

  const [projectPosts, setProjectPosts] = useState(null);
  const [tabList, setTablist] = useState(null);
  const [portfolioReady, setPortfolioReady] = useState(null)
  const { loading, error, data } = useQuery(GET_PROJECTS_AND_CATEGORIES);
  const test = false;

  useEffect(()=>{

    if (data && projectPosts === null && !loading && !error){
      var sortedProjectPosts = cleanPostsData(data, "project");
      setProjectPosts(sortedProjectPosts)

      const tabNames = data.projectCategories.nodes.map(node => node.name);
      setTablist(tabNames);

      setPortfolioReady(true);

    }

  }, [data, projectPosts])


  return (
    <section className="kb_section" id="portfolio">
      <div className="kb_portfolio">
        <div className="container">
          <div className="positon-relative">
            <div className="kb_title">
              <div className="title_flex">
                <div className="left">
                  <span>Projekte</span>
                  <h3>Wasi so gmacht ha</h3>
                </div>
              </div>
            </div>
            {/* End kb_title */}

            {tabList && !loading
            
            ? 

            <div className="portfolio_filter">
               <Tabs>
                <TabList>

                  {tabList.map((tabName, i) => (
                    <Tab key={i}>{tabName}</Tab>
                  ))}
                </TabList>
                {/* End tablist */}

                <div className="portfolio_list has-effect">
                  {tabList.map((category, categoryIndex) => (
                    <TabPanel key={categoryIndex}>
                      <ul>
                        {projectPosts
                          .filter(project => 
                            project.categories.some(cat => cat.name === category))
                          .map((data, dataIndex) => (
                            <PortfolioBox 
                              dataIndex={dataIndex} 
                              data={data}
                            />
                        ))}
                      </ul>
                    </TabPanel>
                  ))}
                  {/* End tabpanel */}
                </div>
              </Tabs>
          </div>
            
            : 
            // loading:

            <div className="portfolio_filter">
            <Tabs>
             <TabList>
              <Tab>
                <Skeleton variant="rectangular" width={70} height={30} />
              </Tab>
              <Tab>
                <Skeleton variant="rectangular" width={70} height={30} />
              </Tab>
              <Tab>
                <Skeleton variant="rectangular" width={70} height={30} />
              </Tab>
              <Tab>
                <Skeleton variant="rectangular" width={70} height={30} />
              </Tab>
             </TabList>
             {/* End tablist */}

             <div className="portfolio_list has-effect">
                 <TabPanel>
                   <ul>
                   <PortfolioSkeleton />
                   <PortfolioSkeleton />
                   <PortfolioSkeleton />
                   <PortfolioSkeleton />
                   <PortfolioSkeleton />
                   <PortfolioSkeleton />
                   </ul>
                 </TabPanel>
               {/* End tabpanel */}
             </div>
           </Tabs>
       </div>


            
            }
            
            
            
            


          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
