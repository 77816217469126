import { useState, useEffect } from 'react';
import axios from 'axios';

const useMailSender = () => {
  const [nonce, setNonce] = useState('');

  const WEBSITE_TITLE = "k3vin.ch";
  const NONCE_URL = "https://kevinadrian.ch/wp/wp-json/kb/nonce";
  const API_URL = 'https://kevinadrian.ch/wp/wp-json/kb/send-email';


  useEffect(() => {
    const fetchNonce = async () => {
      try {
        const response = await axios.get(NONCE_URL);
        setNonce(response.headers['x-wp-nonce']);
      } catch (error) {
        console.error('Fehler beim Abrufen der Nonce:', error);
      }
    };
    fetchNonce();
  }, []);

  const sendEmail = async (name, sendermail, phone, subject, message) => {
    try {
      const formattedMessage = `
      <strong>Name:</strong> ${name}<br />
      <strong>E-Mail:</strong> ${sendermail}<br /><br />
      <strong>Telefon:</strong> ${phone}<br />
      <strong>Betreff:</strong> ${subject} <br />
      ----------------------------------<br /><br />
      <strong>Nachricht:</strong><br />
      ${message}<br /><br />
      ----------------------------------<br />
      Diese Nachricht wurde von der Website via ${WEBSITE_TITLE} Kontaktformular versendet.
      `;
      
      const response = await axios.post(API_URL,
        {
          name: name,
          sendermail: sendermail,
          subject: "📧 ✨ " + subject + " ✨ 📧",
          message: formattedMessage,
        },
        {
          headers: {
            'X-WP-Nonce': nonce,
          },
        }
      );
      alert(response.data);
    } catch (error) {
      console.error('Es gab ein Problem beim Versenden der E-Mail:', error);
    }
  };

  return {
    sendEmail,
  };
};

export default useMailSender;
