import React, { useState } from "react";
import ContactPopUp from "./ContactPopUp";





const CallToAction = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <section className="kb_section" id="contact">
      <div
        className="kb_talk bg_image_props"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/callto-action/Call2Action.png"
          })`,
        }}
      >
        <div className="shape">
          <img className="svg" src="/img/svg/paper.svg" alt="partners brand" />
        </div>
        {/* End shape */}

        <div className="background" id="talk">
          <a className="player"></a>
          <div className="overlay"></div>
        </div>
        {/* End background */}

        <div className="talk_inner">
          <div className="text">
            <h3>Wei mir zämä schaffe?</h3>
          </div>
          <div className="button">
            <button className="white-fill-bg" onClick={toggleModal}>
              Afrag schicke
            </button>
          </div>
        </div>
        {/* End talk_inner */}
      </div>

        <ContactPopUp isOpen = {isOpen} toggleModal={toggleModal} />
    </section>
  );
};

export default CallToAction;
