import React, {useState} from 'react'
import { useNavigate } from "react-router-dom";
import { stripHTML } from '../../backend/api/api-functions';


export default function BlogBox({data}) {

    const navigate = useNavigate()

    function linkblogPost(){
      navigate(`/blogpost/redirect/...`, { state: { cachedPost: data } });
    }

      
  return (
    
    <li>
        <div className="list_inner">
        <div className="image" onClick={linkblogPost}>
            <img src="/img/placeholders/4-3.jpg" alt="thumb" />
            <div
            className="main"
            style={{
                backgroundImage: `url(${
                data.thumbnail
                })`,
            }}
            ></div>
        </div>
        {/* End image */}

        <div className="details">
            <h3 className="title" onClick={linkblogPost}>
            {stripHTML(data.excerpt)}
            </h3>
            <p className="date">
            By <a href="#">{data.author}</a> <span>{data.date}</span>
            </p>
        </div>
        {/* End details */}
 
        </div>
    </li>

  )
}
